.login-page {
  width: 100vw;
  min-height: 100vh; }
  .login-page .login-container {
    width: 600px;
    max-width: 100%;
    min-height: 100vh;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 2rem;
    background: rgba(255, 255, 255, 0.95);
    position: relative; }
    @media (max-width: 600px) {
      .login-page .login-container {
        padding-left: 0.75rem;
        padding-right: 0.75rem; } }
  .login-page .login-background {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: calc(100vw - 600px);
    background: url(/_next/static/images/login-bg-e62ea59d73236f4b348046cc7e1f2e59.png) center right no-repeat;
    background-size: cover; }
    @media (max-width: 600px) {
      .login-page .login-background {
        width: 100%; } }
