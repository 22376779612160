.associate-page .associate-title {
  top: 50%;
  left: 0;
  transform: translateY(-50%); }

.associate-page .associate-bg {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .associate-page .associate-bg img {
    min-height: 100%; }

.associate-page .first-card {
  margin-top: -1.5rem;
  position: relative; }

@media (max-width: 600px) {
  .associate-page .associate-features {
    text-align: center; } }
