header > div,
footer > div {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }
  header > div nav,
  footer > div nav {
    display: flex;
    align-items: center; }
    header > div nav a,
    footer > div nav a {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      color: rgba(0, 0, 0, 0.9);
      font-size: 1rem; }
  header > div a,
  footer > div a {
    text-decoration: none; }
  header > div button.MuiButtonBase-root span,
  footer > div button.MuiButtonBase-root span {
    font-size: 1rem;
    font-weight: 600; }

header > div {
  height: 4rem; }

header {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  background-color: white;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 499; }
  header .logo img {
    height: 64px; }

footer {
  background-color: white;
  box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.1), 0px -4px 5px 0px rgba(0, 0, 0, 0.07), 0px -1px 10px 0px rgba(0, 0, 0, 0.06);
  padding-bottom: 3rem; }

footer > div {
  padding-top: 2.5rem; }
  footer > div nav a {
    color: #b22e41; }
  footer > div .copyright {
    font-size: 0.8rem; }
    footer > div .copyright p {
      margin-bottom: 0.25rem; }
    footer > div .copyright a {
      color: rgba(0, 0, 0, 0.9);
      text-decoration: none; }

main.main-section {
  min-height: calc(100vh - 180px);
  padding-top: 4rem; }

@media (max-width: 960px) {
  footer > div {
    flex-flow: column; }
    footer > div > div {
      margin-top: 1rem;
      margin-bottom: 1rem; }
  header .logo img {
    height: 40px; } }
