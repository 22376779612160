.home-page .search {
  position: relative;
  overflow: hidden; }
  .home-page .search .search-bg {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
    .home-page .search .search-bg img {
      min-height: 100%; }
  .home-page .search .search-box {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding: 5rem 0; }
    .home-page .search .search-box .search-form {
      background-color: rgba(255, 255, 255, 0.9);
      padding: 1.5rem;
      border-radius: 0.5rem;
      max-width: 500px;
      margin-right: 2.5rem; }
    .home-page .search .search-box .search-info {
      background-color: #b22e41;
      color: white;
      max-width: 580px;
      margin-left: 2.5rem;
      padding: 1.5rem;
      border-radius: 0.5rem; }

.home-page section {
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .home-page section.contrast {
    background-color: #b22e41;
    color: white; }
    .home-page section.contrast a {
      color: white; }
  .home-page section.most-searched {
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px 0 inset; }
    @media (max-width: 600px) {
      .home-page section.most-searched {
        text-align: center; }
        .home-page section.most-searched a {
          text-align: center;
          width: 100%; } }
    .home-page section.most-searched h2,
    .home-page section.most-searched h3 {
      font-weight: 600;
      margin-bottom: 0.5rem; }
    .home-page section.most-searched .link-list {
      display: flex;
      flex-direction: column; }
      .home-page section.most-searched .link-list a {
        margin: 0.125rem 0; }
  .home-page section.advertise {
    padding-bottom: 4rem;
    padding-top: 4rem; }
    .home-page section.advertise > div {
      flex-direction: row;
      display: flex; }
    .home-page section.advertise .advertise-left,
    .home-page section.advertise .advertise-right {
      flex: 1 1;
      width: 50%; }
    .home-page section.advertise .advertise-left {
      align-items: center;
      display: flex; }
      .home-page section.advertise .advertise-left picture,
      .home-page section.advertise .advertise-left img {
        display: block;
        max-width: 125%;
        margin-left: -12vw;
        border-radius: 0 20rem 20rem 0; }
    .home-page section.advertise .advertise-right {
      text-align: center; }
      @media (min-width: 960px) {
        .home-page section.advertise .advertise-right {
          padding: 2rem; } }
      .home-page section.advertise .advertise-right .advertise-columns {
        display: flex; }
        .home-page section.advertise .advertise-right .advertise-columns > div {
          flex: 1; }

.home-header + .main-section {
  padding-top: 0; }

@media (min-width: 960px) {
  .home-header {
    background-color: transparent;
    position: absolute;
    z-index: 999;
    width: 100%;
    box-shadow: none;
    height: 200px;
    padding-top: 4rem; }
    .home-header .logo-container,
    .home-header .main-nav {
      background-color: white; }
    .home-header .logo-container {
      padding: 2rem;
      padding-right: 4rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 400px;
      height: 135px;
      border-radius: 0 5rem 5rem 0;
      margin-left: -32px; }
    .home-header .main-nav {
      padding: 0.5rem;
      border-radius: 2rem; }
      .home-header .main-nav a {
        margin-left: 3rem; } }

@media (max-width: 960px) {
  .home-page .search .search-box {
    flex-direction: column;
    padding: 2rem 0; }
    .home-page .search .search-box .search-form {
      margin-right: 0;
      margin-bottom: 1rem;
      width: 100%;
      max-width: 100%; }
    .home-page .search .search-box .search-info {
      margin-left: 0;
      margin-top: 1rem;
      width: 100%;
      max-width: 100%; }
  .home-page .advertise-left {
    display: none !important; } }

@media (max-width: 600px) {
  .home-page section.your-area .zero-carousel .zero-carousel-slide:first-child {
    margin-left: 0.5rem; }
  .home-header + .main-section {
    padding-top: 60px; } }
