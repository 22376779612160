.react-image-gallery .image-gallery-content {
  background-color: #000; }

.react-image-gallery .image-gallery-thumbnails-wrapper {
  background-color: #222; }

.react-image-gallery .image-gallery-slide-wrapper {
  width: 100%; }

.react-image-gallery .image-gallery-thumbnail {
  width: 96px; }
  .react-image-gallery .image-gallery-thumbnail.active {
    border-color: #b22e41; }

.react-image-gallery .image-gallery-thumbnail-inner {
  text-align: center;
  background-color: #000;
  height: 54px; }

.react-image-gallery .image-gallery-thumbnail-inner img,
.react-image-gallery .image-gallery-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center center;
  outline: none !important; }

.react-image-gallery .image-gallery-slide {
  outline: none !important; }
  .react-image-gallery .image-gallery-slide div {
    width: 100%;
    height: 0;
    padding-top: 75%;
    position: relative;
    outline: none !important; }

.react-image-gallery .image-gallery-content.fullscreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; }
  .react-image-gallery .image-gallery-content.fullscreen .image-gallery-slide div {
    height: calc(100vh - 72px);
    padding: 0; }
