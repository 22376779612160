.realestate-card {
  transition: transform ease-in-out 300ms, box-shadow ease-in-out 300ms !important;
  min-height: 100%;
  position: relative;
  border: thin solid rgba(0, 0, 0, 0.23); }
  .realestate-card.realestate-card-partnership .MuiCardContent-root {
    min-height: 200px; }
  .realestate-card:hover {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
    transform: translateY(-4px);
    z-index: 400; }
  .realestate-card .realestate-card-badge {
    background-color: #b22e41;
    border-radius: 2rem;
    display: inline-flex;
    color: white;
    padding: 0.5rem 1rem;
    align-items: center;
    position: absolute;
    top: 178px;
    right: 1rem; }

.realestate-card-partnership {
  min-height: auto; }
