.admin-panel {
  background: #f4f4f4;
  min-height: 100vh; }
  .admin-panel button span:not(.material-icons) {
    text-transform: uppercase !important; }
  .admin-panel .admin-header-avatar {
    height: 2.5rem !important;
    width: 2.5rem !important; }
  .admin-panel .no-min-height {
    padding-right: 0 !important;
    overflow: hidden; }
    .admin-panel .no-min-height .main-section {
      margin: 0; }
  .admin-panel .main-view {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column; }
  .admin-panel .admin-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(250, 250, 250, 0.6);
    height: auto; }
  .admin-panel .main-section {
    width: 100%;
    margin-top: 1rem; }
  .admin-panel .admin-card-body-height-1:not(.grow) {
    height: 10rem; }
  .admin-panel .card-title + .admin-card-body-height-1:not(.grow) {
    height: 6.5rem; }
  .admin-panel .admin-card-body-height-1.grow {
    min-height: 10rem; }
  .admin-panel .card-title + .admin-card-body-height-1.grow {
    min-height: 6.5rem; }
  .admin-panel .admin-card-body-height-2:not(.grow) {
    height: 20rem; }
  .admin-panel .card-title + .admin-card-body-height-2:not(.grow) {
    height: 16.5rem; }
  .admin-panel .admin-card-body-height-2.grow {
    min-height: 20rem; }
  .admin-panel .card-title + .admin-card-body-height-2.grow {
    min-height: 16.5rem; }
  .admin-panel .admin-card-body-height-3:not(.grow) {
    height: 30rem; }
  .admin-panel .card-title + .admin-card-body-height-3:not(.grow) {
    height: 26.5rem; }
  .admin-panel .admin-card-body-height-3.grow {
    min-height: 30rem; }
  .admin-panel .card-title + .admin-card-body-height-3.grow {
    min-height: 26.5rem; }
  .admin-panel .admin-card-body-height-4:not(.grow) {
    height: 40rem; }
  .admin-panel .card-title + .admin-card-body-height-4:not(.grow) {
    height: 36.5rem; }
  .admin-panel .admin-card-body-height-4.grow {
    min-height: 40rem; }
  .admin-panel .card-title + .admin-card-body-height-4.grow {
    min-height: 36.5rem; }
  .admin-panel .admin-card-body-height-5:not(.grow) {
    height: 50rem; }
  .admin-panel .card-title + .admin-card-body-height-5:not(.grow) {
    height: 46.5rem; }
  .admin-panel .admin-card-body-height-5.grow {
    min-height: 50rem; }
  .admin-panel .card-title + .admin-card-body-height-5.grow {
    min-height: 46.5rem; }
  .admin-panel .admin-card-body-height-6:not(.grow) {
    height: 60rem; }
  .admin-panel .card-title + .admin-card-body-height-6:not(.grow) {
    height: 56.5rem; }
  .admin-panel .admin-card-body-height-6.grow {
    min-height: 60rem; }
  .admin-panel .card-title + .admin-card-body-height-6.grow {
    min-height: 56.5rem; }
  .admin-panel .admin-card-body-height-full {
    height: calc(100vh - (4rem * 2) - 5rem);
    overflow: hidden; }
  .admin-panel .admin-card-body-height-minfull {
    min-height: calc(100vh - (4rem * 2) - 5rem);
    overflow: auto; }
  @media (max-width: 600px) {
    .admin-panel .admin-page-title {
      justify-content: center; } }
  .admin-panel .admin-action {
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 3.5rem; }
    @media (max-width: 600px) {
      .admin-panel .admin-action {
        display: flex;
        justify-content: center;
        position: relative; } }
    .admin-panel .admin-action > button:last-child {
      background: #4caf50 !important; }
    .admin-panel .admin-action > button:only-child {
      min-width: 235px !important; }

.admin-header-options {
  position: fixed !important;
  top: 3.5rem;
  right: 2rem;
  width: 16rem !important;
  z-index: 1000;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem !important; }
  .admin-header-options .avatar {
    width: 60px !important;
    height: 60px !important; }

.admin-loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 999; }

.admin-integration-card.active .check-icon,
.admin-integration-card.active .cloud-icon,
.admin-settings-card.active .check-icon,
.admin-settings-card.active .cloud-icon {
  color: #4caf50; }

.admin-integration-card .check-icon,
.admin-settings-card .check-icon {
  width: 24px;
  height: 24px;
  color: #eeeeee; }

.admin-integration-card .cloud-icon,
.admin-settings-card .cloud-icon {
  width: 27px;
  height: 24px;
  color: #debe0b; }

.admin-settings-card-add {
  border: 2px dashed #707070;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 168px;
  margin-top: 15px;
  cursor: pointer; }
  .admin-settings-card-add .add-icon {
    font-size: 70px;
    font-weight: bold;
    color: #4caf50; }

.curation-mini-items > span {
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px; }

.partnership-search-card {
  border-radius: 1rem 8rem 8rem 1rem !important; }

.admin-profile .avatar {
  width: 6rem;
  height: 6rem; }

.segmentation h2 {
  margin-bottom: 0; }

.curation-photo-buttons {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10; }

.new-curation-gallery .image-gallery-image:not(.fullscreen) .image-gallery-slides .image-gallery-image {
  max-height: 600px; }

.admin-menu-selected {
  color: #892432 !important;
  background-color: #f9e6e9 !important; }
  .admin-menu-selected .material-icons {
    color: #892432 !important; }

.admin-menu-nested {
  padding-left: 2rem !important; }

.grid-no-padding-bottom > div {
  padding-bottom: 0 !important; }

@media (max-width: 600px) {
  .mobile-centered {
    justify-content: center !important; } }

@media (max-width: 600px) {
  .mobile-direction-column {
    flex-direction: column !important; } }

.admin-realty-edit-form .form-section > div > *:not(:last-child) {
  margin-bottom: 12px; }

.admin-summary-card-icon {
  font-size: 3rem; }

.admin-summary-chart {
  height: 100%; }
