.container-loader {
  position: relative;
  width: 100%;
  height: 100%; }
  .container-loader .loader {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .container-loader .loader.loader-hide {
      display: none; }
