.realestate-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background-color: white;
  z-index: 200;
  margin-top: -77px;
  box-shadow: 0px -5px 5px -3px rgba(0, 0, 0, 0.2), 0px -8px 10px 1px rgba(0, 0, 0, 0.14), 0px -3px 14px 2px rgba(0, 0, 0, 0.12);
  display: flex; }

.realestate-sticky .realestate-blue-invert-btn,
.floating-actions .realestate-blue-invert-btn {
  background-color: #2e83b2;
  color: white;
  transition: all 0.3s ease-in-out;
  padding: 8px; }
  .realestate-sticky .realestate-blue-invert-btn:hover,
  .floating-actions .realestate-blue-invert-btn:hover {
    background-color: #246589; }
  .realestate-sticky .realestate-blue-invert-btn:active,
  .floating-actions .realestate-blue-invert-btn:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.realestate-sticky .detail-share-btn,
.floating-actions .detail-share-btn {
  font-size: 1rem; }

.realestate-detail .media-mosaic {
  cursor: pointer;
  overflow: hidden;
  background-color: #000; }
  .realestate-detail .media-mosaic .media-mosaic-container {
    max-height: 28vw;
    min-height: 28vw;
    display: block; }
  .realestate-detail .media-mosaic .mosaic-small {
    max-height: 14vw;
    min-height: 14vw; }
  .realestate-detail .media-mosaic .mosaic-wrapper {
    width: 100%;
    height: 100%; }

.realestate-detail .realestate-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center; }

.realestate-detail .image-gallery-content:not(.fullscreen) .image-gallery-slide div {
  padding-top: 100%; }
  .realestate-detail .image-gallery-content:not(.fullscreen) .image-gallery-slide div img {
    object-fit: cover; }

.realestate-detail .realestate-title {
  background-color: #b22e41;
  color: white;
  padding: 2rem 0; }
  @media (max-width: 600px) {
    .realestate-detail .realestate-title {
      padding-bottom: 0;
      padding-top: 1rem; } }

.realestate-detail .realestate-details {
  padding: 2rem 0; }
  .realestate-detail .realestate-details .caracteristic-item {
    width: 65px; }
  .realestate-detail .realestate-details .realestate-map {
    height: 30vh;
    min-height: 400px;
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    background: url("/images/mapa.png") center center;
    background-size: cover; }
    .realestate-detail .realestate-details .realestate-map .realestate-reveal-map {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

.realestate-detail .realestate-related {
  padding: 4rem 0;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px 0 inset;
  background-color: white; }

.realestate-detail .floating-actions {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  display: flex;
  justify-content: space-between; }

.realestate-detail .detail-exclusive-badge {
  background-color: #b22e41;
  border-radius: 2rem;
  display: inline-flex;
  color: white;
  padding: 0.5rem 1rem;
  align-items: center; }

.realestate-detail .realestate-user .realestate-user-title {
  margin-bottom: 1.5rem; }
  @media (max-width: 600px) {
    .realestate-detail .realestate-user .realestate-user-title {
      text-align: center; } }

.realestate-detail .realestate-user .realestate-user-profile {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 960px) {
    .realestate-detail .realestate-user .realestate-user-profile {
      justify-content: start; } }
  @media (max-width: 600px) {
    .realestate-detail .realestate-user .realestate-user-profile {
      flex-direction: column;
      text-align: center; }
      .realestate-detail .realestate-user .realestate-user-profile .thumbnail {
        margin-bottom: 1rem; } }

.realestate-detail .realestate-user .realestate-user-action {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (min-width: 960px) {
    .realestate-detail .realestate-user .realestate-user-action {
      justify-content: flex-end; } }
  @media (max-width: 600px) {
    .realestate-detail .realestate-user .realestate-user-action {
      text-align: center; } }

.realestate-gallery-lightbox .image-gallery {
  background-color: #222; }
  .realestate-gallery-lightbox .image-gallery .image-gallery-thumbnails {
    padding: 0.5rem 0; }
  .realestate-gallery-lightbox .image-gallery .image-gallery-slide div {
    text-align: center;
    background-color: black;
    outline: none !important; }
    .realestate-gallery-lightbox .image-gallery .image-gallery-slide div img {
      height: 540px;
      max-width: 100%;
      width: auto;
      outline: none !important; }
  .realestate-gallery-lightbox .image-gallery .image-gallery-thumbnail-inner {
    text-align: center;
    background-color: black;
    height: 54px; }
    .realestate-gallery-lightbox .image-gallery .image-gallery-thumbnail-inner img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center center; }
  .realestate-gallery-lightbox .image-gallery .image-gallery-thumbnail.active {
    border-color: #b22e41; }
  .realestate-gallery-lightbox .image-gallery .image-gallery-index {
    left: 0;
    top: 1rem;
    right: inherit; }

.realestate-gallery-lightbox .image-gallery-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  color: white; }
  .realestate-gallery-lightbox .image-gallery-close:hover {
    background-color: rgba(0, 0, 0, 0.6); }

@media (max-width: 960px) {
  .realestate-detail .media-mosaic .media-mosaic-container {
    max-height: 50vh;
    width: 100%; }
  .realestate-detail .realestate-title > div {
    flex-direction: column;
    text-align: center; } }
