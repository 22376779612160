/** ISSUERS **/
/** Images **/
.rccs {
  margin: 0 auto;
  perspective: 1000px;
  width: 312px; }
  .rccs__card {
    height: 196.74612px;
    margin: 0 auto;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.4s linear;
    width: 312px; }
    .rccs__card--front, .rccs__card--back {
      backface-visibility: hidden;
      background: linear-gradient(25deg, #939393, #717171);
      border-radius: 15.6px;
      color: #fff;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      transform-style: preserve-3d;
      width: 100%;
      transition: opacity 0.5s;
      box-shadow: 1px 1px 3px rgba(51, 51, 51, 0.2); }
    .rccs__card--front {
      z-index: 20; }
    .rccs__card--back {
      transform: rotateY(180deg);
      opacity: 0; }
      .rccs__card--back .rccs__issuer {
        background-position: bottom center;
        bottom: 5%;
        left: 50%;
        opacity: 0.6;
        right: auto;
        top: auto;
        transform: translateX(-50%); }
    .rccs__card__background {
      height: 200%;
      left: -170%;
      position: absolute;
      top: -60%;
      transform: rotate(25deg);
      transition: all 0.5s ease-out;
      width: 150%;
      left: -170%; }
    .rccs__card--flipped {
      transform: rotateY(180deg); }
      .rccs__card--flipped .rccs__card--front {
        z-index: 10;
        opacity: 0; }
      .rccs__card--flipped .rccs__card--back {
        z-index: 20;
        opacity: 1; }
    .rccs__card--unknown > div {
      background: linear-gradient(25deg, #999, #999);
      box-shadow: none; }
    .rccs__card--unknown .rccs__issuer {
      visibility: hidden; }
    .rccs__card:not(.rccs__card--unknown) .rccs__card__background {
      left: -22%; }
    .rccs__card--amex .rccs__card__background {
      background: linear-gradient(25deg, #308c67, #a3f2cf); }
    .rccs__card--amex .rccs__issuer {
      background-image: url(/_next/static/images/amex-logo-f5790cba92076a0992f10d6e6d54b34b.svg); }
    .rccs__card--amex .rccs__cvc__front {
      opacity: 0.5;
      visibility: visible; }
    .rccs__card--dankort .rccs__card__background {
      background: linear-gradient(25deg, #ccc, #999); }
    .rccs__card--dankort .rccs__issuer {
      background-image: url(/_next/static/images/dankort-logo-691c5d00446fbcd868025601bbbaeaec.svg); }
    .rccs__card--dinersclub > div {
      color: #555; }
    .rccs__card--dinersclub .rccs__card__background {
      background: linear-gradient(25deg, #fff, #eee); }
    .rccs__card--dinersclub .rccs__issuer {
      background-image: url(/_next/static/images/dinersclub-logo-02145212a8555e5735b55f575b70f306.svg); }
    .rccs__card--discover > div {
      color: #555; }
    .rccs__card--discover .rccs__card__background {
      background: linear-gradient(25deg, #fff, #eee); }
    .rccs__card--discover .rccs__issuer {
      background-image: url(/_next/static/images/discover-logo-079ea9eb311f70d6643c08bfd4a7df98.svg); }
    .rccs__card--elo .rccs__card__background {
      background: linear-gradient(25deg, #211c18, #aaa7a2); }
    .rccs__card--elo .rccs__issuer {
      background-image: url(/_next/static/images/elo-logo-f7d2af9b8330efb438bce18a690549aa.svg); }
    .rccs__card--hipercard .rccs__card__background {
      background: linear-gradient(25deg, #8b181b, #de1f27); }
    .rccs__card--hipercard .rccs__issuer {
      background-image: url(/_next/static/images/hipercard-logo-1a78690f4d1f1cd0af2fb94367d11902.svg); }
    .rccs__card--jcb .rccs__issuer {
      background-image: url(/_next/static/images/jcb-logo-0d082ee101894224a367efaebe955aa1.svg); }
    .rccs__card--laser .rccs__issuer {
      background-image: url(/_next/static/images/laser-logo-4aa046f2f88a134acfe82d96cf68ab80.svg); }
    .rccs__card--maestro > div, .rccs__card--mastercard > div {
      color: #555; }
    .rccs__card--maestro .rccs__card__background, .rccs__card--mastercard .rccs__card__background {
      background: linear-gradient(25deg, #fbfbfb, #e8e9e5); }
    .rccs__card--maestro .rccs__issuer {
      background-image: url(/_next/static/images/maestro-logo-3f91a770120cf9815b4b7f80cc8515f7.svg); }
    .rccs__card--mastercard .rccs__issuer {
      background-image: url(/_next/static/images/mastercard-logo-94392841bfdf83267f465b36c5862f93.svg); }
    .rccs__card--unionpay .rccs__issuer {
      background-image: url(/_next/static/images/unionpay-logo-16895c3b3c7628b0e05a60637b05ddf9.svg); }
    .rccs__card--visa .rccs__card__background, .rccs__card--visaelectron .rccs__card__background {
      background: linear-gradient(25deg, #0f509e, #1399cd); }
    .rccs__card--visa .rccs__issuer, .rccs__card--visaelectron .rccs__issuer {
      background-size: 75%; }
    .rccs__card--visa .rccs__issuer {
      background-image: url(/_next/static/images/visa-logo-6c68420a61d36d3614f26b2375f29cfc.svg); }
    .rccs__card--visaelectron .rccs__issuer {
      background-image: url(/_next/static/images/visaelectron-logo-7daef54ff44d4179b9392036be118cdc.svg); }
  .rccs__number {
    clear: both;
    font-family: Consolas, Courier, monospace;
    font-size: 18px;
    left: 10%;
    position: absolute;
    top: 45%; }
    .rccs__number.rccs__number--large {
      font-size: 15.3px; }
  .rccs__name {
    bottom: 15%;
    font-family: Consolas, Courier, monospace;
    font-size: 12px;
    left: 10%;
    line-height: 1;
    overflow: hidden;
    position: absolute;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: uppercase;
    width: 60%; }
  .rccs__expire {
    bottom: 15%;
    font-size: 0;
    line-height: 1;
    position: absolute;
    right: 10%; }
    .rccs__expire > * {
      vertical-align: middle; }
    .rccs__expire__valid {
      font-size: 10px;
      margin-bottom: 5px; }
    .rccs__expire__value {
      font-family: Consolas, Courier, monospace;
      font-size: 12px; }
  .rccs__number, .rccs__name, .rccs__expire, .rccs__cvc {
    opacity: 0.5;
    transition: opacity 0.3s; }
  .rccs__chip {
    background-image: url(/_next/static/images/chip-124de1b59eac07854e6b93896d3d9a7c.svg);
    background-repeat: no-repeat;
    background-size: contain;
    height: 28.36364px;
    left: 10%;
    position: absolute;
    top: 10%;
    width: 44.57143px; }
  .rccs__issuer {
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    height: 23%;
    position: absolute;
    right: 10%;
    top: 10%;
    width: 40%; }
  .rccs__stripe {
    background-color: #2a1d16;
    height: 22%;
    left: 0;
    position: absolute;
    top: 9%;
    width: 100%; }
  .rccs__signature {
    background: repeating-linear-gradient(0.1deg, #fff 20%, #fff 40%, #fea 40%, #fea 44%, #fff 44%);
    height: 18%;
    left: 5%;
    position: absolute;
    top: 35%;
    width: 75%; }
  .rccs__cvc {
    color: #222;
    font-family: Consolas, Courier, monospace;
    font-size: 12px;
    left: 67%;
    line-height: 1;
    position: absolute;
    top: 42%; }
  .rccs__cvc__front {
    font-family: Consolas, Courier, monospace;
    font-size: 10.2px;
    opacity: 0;
    position: absolute;
    right: 10%;
    top: 38%;
    visibility: hidden; }
  .rccs--filled {
    opacity: 0.8 !important; }
  .rccs--focused {
    font-weight: 700;
    opacity: 1 !important; }

@media (max-width: 350px) {
  .rccs {
    max-width: 85vw; }
    .rccs__card {
      width: 85vw;
      height: 53.60071vw; }
    .rccs--front, .rccs--back {
      border-radius: 4.25vw; }
    .rccs__chip {
      height: 7.72727vw;
      width: 12.14286vw; } }

.payment .credit-card-preview {
  margin-top: -5rem;
  margin-bottom: 1rem; }

.payment .bar-code-container {
  margin-top: -4rem;
  margin-bottom: 1rem; }
  .payment .bar-code-container .bar-code img {
    max-width: 100%;
    margin: 0 auto; }

.plan-features {
  font-size: 1rem;
  min-height: 260px; }
  .plan-features ul {
    margin: 2rem 0;
    padding: 0 1rem; }
    .plan-features ul li {
      list-style: none;
      margin: 1rem 0; }

.payment-terms a {
  color: #b22e41;
  text-decoration: none; }

span.payment-done-icon {
  color: #4caf50;
  font-size: 7rem; }
